import { Drawer, DrawerProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { History, HistoryProps } from '../History/History';

export type HistoryDrawerProps = DrawerProps & HistoryProps;

/**
 * Drawer with template history
 */
export const HistoryDrawer: React.FC<HistoryDrawerProps> = ({
  templateId,
  countVersions,
  selectedVersion,
  onSelectedVersionChange,
  onRevertVersion,
  ...drawerProps
}) => {
  const { t } = useTranslation('templates');

  return (
    <Drawer
      position="right"
      overlayOpacity={0}
      {...drawerProps}
      title={t('history.title')}
    >
      <History
        templateId={templateId}
        countVersions={countVersions}
        selectedVersion={selectedVersion}
        onSelectedVersionChange={onSelectedVersionChange}
        onRevertVersion={onRevertVersion}
      />
    </Drawer>
  );
};

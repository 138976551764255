import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { postTemplateVersionsRevert } from '../requests/postTemplateVersionsRevert';

export const usePostTemplateVersionsRevert = (templateId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (versionId: number) =>
      postTemplateVersionsRevert({
        pathParams: { templateId, versionId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.templateVersions, { templateId }],
      });
    },
  });
};

import { useTemplateHistory } from 'hooks';
import { Box, BoxProps, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { HistoryItem, HistoryItemProps } from './HistoryItem';

export type HistoryProps = BoxProps & {
  /**
   * Template id
   */
  templateId: string;

  /**
   * Selected version of template
   */
  selectedVersion?: number;

  /**
   * How many versions has template
   */
  countVersions: number;

  /**
   * Function to handle selected version change
   * @param version - new selected version
   */
  onSelectedVersionChange?: HistoryItemProps['onClick'];

  /**
   * Function to handle revert to specific version
   * @param version - version to revert to
   */
  onRevertVersion?: HistoryItemProps['onRevertVersion'];
};

/**
 * Component to display template history and allow reverting to previous versions
 */
export const History: React.FC<HistoryProps> = ({
  templateId,
  countVersions,
  selectedVersion,
  onSelectedVersionChange,
  onRevertVersion,
  ...boxProps
}) => {
  const { data = [], isLoading, refetch } = useTemplateHistory(templateId);

  /**
   * Refetch history on countVersions change, probably new version was created
   */
  useEffect(() => {
    refetch();
  }, [refetch, countVersions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
      {...boxProps}
    >
      {isLoading && (
        <Box
          sx={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Box>
      )}
      {data.map((item, index) => (
        <HistoryItem
          key={item.version}
          index={countVersions - index}
          data={item}
          isCurrent={item.version === data[0]?.version}
          isSelected={
            selectedVersion === item.version ||
            (index === 0 && !selectedVersion)
          }
          onClick={onSelectedVersionChange}
          onRevertVersion={onRevertVersion}
        />
      ))}
    </Box>
  );
};

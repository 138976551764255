import { apiAuthClient } from 'api/helpers';

import {
  PostTemplateVersionsRevertResponseType,
  PostTemplateVersionsRevertRequestType,
} from '../types';

export const postTemplateVersionsRevert = async ({
  pathParams: { templateId, versionId },
}: PostTemplateVersionsRevertResponseType) => {
  const url = `/api/v2/templates/${templateId}/versions/${versionId}/revert`;

  const { data } =
    await apiAuthClient.post<PostTemplateVersionsRevertRequestType>(url);

  return data.data;
};

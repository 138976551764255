import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  Box,
  Button,
  DefaultProps,
  packSx,
  Text,
  Stack,
} from '@mantine/core';

import { ErrorTwoToneIcon } from 'icons';
import {
  HistorySummary,
  HistorySummaryProps,
} from '../../../../pages/TemplateEdit/History/HistorySummary';

const ACCORDION_ITEM_VALUE = 'accordionItemValue';

type Props = Partial<HistorySummaryProps> & {
  children: ReactNode;
  isError: boolean;
  sx?: DefaultProps['sx'];
};

export const FormAccordion = ({
  children,
  isError,
  sx,
  versionNumber,
  versionDate,
  onOpenHistory,
  isCurrentVersion,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Accordion
      sx={[
        {
          maxWidth: 836,
        },
        ...packSx(sx),
      ]}
      styles={{ content: { padding: 0 } }}
      value={isExpanded ? ACCORDION_ITEM_VALUE : undefined}
      variant="contained"
      transitionDuration={400}
    >
      <Accordion.Item
        sx={theme => ({
          padding: theme.other.spacing(3, 2),
          backgroundColor: theme.white,
          '&[data-active]': {
            backgroundColor: theme.white,
          },
        })}
        value={ACCORDION_ITEM_VALUE}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.other.spacing(0, 2),
            gap: theme.other.spacing(1),
          })}
        >
          <Box>
            <Text size="xl" weight={700}>
              {t('templates:templateDetails')}
            </Text>
            {versionDate !== undefined && versionNumber !== undefined && (
              <HistorySummary
                versionDate={versionDate}
                versionNumber={versionNumber}
                onOpenHistory={onOpenHistory}
                isCurrentVersion={isCurrentVersion ?? true}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {isError && (
              <Box
                sx={theme => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.other.spacing(1),
                })}
              >
                <ErrorTwoToneIcon size={30} color="error" />
                <Text color="red">
                  {t('templates:someFieldsNeedAttention')}
                </Text>
              </Box>
            )}
            <Button
              sx={theme => ({
                fontSize: theme.fontSizes.lg,
                fontWeight: 600,
              })}
              variant="subtle"
              onClick={() => {
                setIsExpanded(expanded => !expanded);
              }}
            >
              {isExpanded ? t('common:collapse') : t('common:expand')}
            </Button>
          </Box>
        </Box>
        <Accordion.Panel>
          <Box
            sx={theme => ({
              padding: theme.other.spacing(4, 2),
            })}
          >
            <Stack sx={{ gap: 32 }}>{children}</Stack>
          </Box>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const RefreshIcon = ({ color, size, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size || 17}
      height={size || 16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 15C4.63401 15 1.5 11.866 1.5 8C1.5 5.76904 2.54367 3.78183 4.1694 2.5M8.5 1C12.366 1 15.5 4.13401 15.5 8C15.5 10.231 14.4563 12.2182 12.8306 13.5M12.5 10.5V14H16M1 2H4.5V5.5"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Box>
  );
};

import { Box, BoxProps, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '../UserAvatar';
import { useGetUser } from '../../../api/account';
import { UserType } from '../../../types';

export type UserData = Partial<
  Pick<UserType, 'email' | 'surname' | 'name' | 'avatar'>
> & {
  /**
   * If user is deleted
   */
  isDeleted?: boolean;
};

export type UserProps = BoxProps & {
  /**
   * Avatar size in px
   */
  avatarSize?: number;

  /**
   * Max width of label
   */
  maxWidthLabel?: number;

  /**
   * User data
   * If not provided, user probably is system user
   */
  data?: UserData;

  /**
   * Mark user as current user
   */
  markMe?: boolean;
};

/**
 * Component to display user data
 */
export const User: React.FC<UserProps> = ({
  data,
  avatarSize = 20,
  markMe = false,
  maxWidthLabel,
  ...boxProps
}) => {
  const { t } = useTranslation('common');
  const { data: currentUser } = useGetUser();
  const { name, surname, avatar, email, isDeleted } = data || {};

  const getLabel = () => {
    if (data) {
      if (data.isDeleted) {
        return t('userDeleted');
      }

      if (data.name && data.surname) {
        return `${data.name} ${data.surname}`;
      }
      return data.email;
    }
    return t('systemUser');
  };

  const label = getLabel();

  return (
    <Box
      {...boxProps}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <Box
        sx={{
          width: avatarSize,
        }}
      >
        <UserAvatar
          size={avatarSize}
          name={name}
          surname={surname}
          avatar={avatar}
          isDeleted={isDeleted}
        />
      </Box>
      <Box maw={maxWidthLabel}>
        <Text
          size="sm"
          weight={500}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Text>
      </Box>
      {markMe && !isDeleted && currentUser?.email === email && (
        <Box
          sx={{
            flex: '1 1 auto',
          }}
        >
          <Text size="sm" weight={500}>
            ({t('you')})
          </Text>
        </Box>
      )}
    </Box>
  );
};

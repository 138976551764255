import { ThemeComponentType } from '../types';

export const Drawer: ThemeComponentType = {
  styles: () => ({
    root: {},
    header: {
      padding: `16px 16px 0 16px !important`,
    },
    title: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    body: {
      padding: `16px !important`,
      overflow: 'auto',
      height: 'calc(100% - 52px)', // 52px is the height of the title
    },
  }),
};

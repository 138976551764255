import { useGetTemplateVersion } from '../api/templates/hooks';

/**
 * Fetches template version data
 */
export const useTemplateVersion = (templateId: string, version?: number) =>
  useGetTemplateVersion(
    {
      pathParams: { templateId, version },
    },
    { enabled: Boolean(templateId) && Boolean(version) },
  );

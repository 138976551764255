import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { SignIcon } from 'icons';
import { TextInput } from 'components/shared';

const Signatures = ({ attributes, node, editor, isActive }) => {
  const { t } = useTranslation('templates');
  const [firstPerson, setFirstPerson] = useState(
    node.data.get('firstPerson') || '',
  );
  const [secondPerson, setSecondPerson] = useState(
    node.data.get('secondPerson') || '',
  );

  const getInputSharedProps = hasError => ({
    sx: theme => {
      const activeBorderColor =
        theme.focusRingStyles.inputStyles(theme).borderColor;

      const getBorderColor = () => {
        if (isActive) {
          return activeBorderColor;
        }

        return hasError ? theme.colors.red[0] : undefined;
      };

      return {
        input: {
          backgroundColor: hasError ? theme.colors.red[1] : undefined,
          borderColor: getBorderColor(),
        },
      };
    },
    icon: <SignIcon />,
  });

  return (
    <Box
      {...attributes}
      sx={theme => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: theme.other.spacing(2),
        gap: theme.other.spacing(1),
        userSelect: 'none',
      })}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <TextInput
        disabled={editor.props.readOnly}
        {...getInputSharedProps(firstPerson.length === 0)}
        label={t('templates:firstPerson')}
        placeholder={t('templates:firstPerson')}
        value={firstPerson}
        onChange={e => {
          setFirstPerson(e.target.value);
        }}
        onBlur={() => {
          editor.updateNodeData(node, {
            firstPerson,
          });
        }}
      />
      <TextInput
        disabled={editor.props.readOnly}
        {...getInputSharedProps(secondPerson.length === 0)}
        label={t('templates:secondPerson')}
        placeholder={t('templates:secondPerson')}
        value={secondPerson}
        onChange={e => {
          setSecondPerson(e.target.value);
        }}
        onBlur={() => {
          editor.updateNodeData(node, {
            secondPerson,
          });
        }}
      />
    </Box>
  );
};

export const signatures = {
  onKeyDown: (e, editor, next) => {
    if (
      e.key === 'Enter' &&
      editor.value.blocks.some(block => block.type === 'signatures')
    ) {
      e.preventDefault();

      editor.moveToEndOfNode(editor.value.endBlock).insertBlock('paragraph');

      return true;
    }

    return next();
  },
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'signatures') {
      return (
        <Signatures
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    blocks: {
      signatures: {
        isVoid: true,
      },
    },
  },
};

import React from 'react';
import { Box, BoxProps, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFormatUserDate } from '../../../hooks';

export type HistorySummaryProps = BoxProps & {
  /**
   * Function to handle history drawer open
   */
  onOpenHistory?: () => void;

  /**
   * Version number
   */
  versionNumber: number;

  /**
   * Version date
   */
  versionDate: string;

  /**
   * Is current version template
   * true - current version
   * false - version from history
   */
  isCurrentVersion: boolean;
};

/**
 * Component to display current template version
 */
export const HistorySummary: React.FC<HistorySummaryProps> = ({
  onOpenHistory,
  versionNumber,
  versionDate,
  isCurrentVersion,
  ...boxProps
}) => {
  const { t } = useTranslation('templates');
  const dateFormater = useFormatUserDate();

  return (
    <Box
      mt={4}
      {...boxProps}
      sx={theme => ({
        color: theme.colors.gray[6],
      })}
    >
      {t(
        isCurrentVersion
          ? 'templates:history.currentVersion'
          : 'templates:history.oldVersion',
        {
          date: dateFormater(versionDate),
          version: versionNumber || 1, // 0 can be provided via API for old templates, created before the implementation of version control features
        },
      )}
      {onOpenHistory && (
        <Button
          h={18}
          p={2}
          variant="subtle"
          display="inline-block"
          onClick={onOpenHistory}
        >
          {t('templates:history.look')}
        </Button>
      )}
    </Box>
  );
};

import { TemplateType } from '../types';

/**
 * Merges template data and version data
 * @param templateData
 * @param versionData
 */
export const mergeTemplateAndVersion = (
  templateData?: TemplateType,
  versionData?: Partial<TemplateType>,
): TemplateType | undefined => {
  if (!templateData || !versionData) {
    return templateData;
  }

  return {
    ...templateData,
    ...versionData,
    __links: [
      ...(versionData?.__links || []),
      ...(templateData?.__links || []),
    ],
  };
};

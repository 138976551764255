import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

import { usePatchTemplate } from 'api/templates';
import { SIGN_TYPES, URLS } from 'consts';
import { useServerErrors, useShowNotification } from 'hooks';
import { EyeIcon, SaveIcon } from 'icons';
import { ActionItemType, TemplateType } from 'types';
import { ActionsBar } from 'components/shared';
import {
  AutoNumeration,
  CertificateLanguages,
  FormAccordion,
  Name,
  Permissions,
  Properties,
  SignType,
  TemplateType as TemplateTypeField,
} from 'components/Templates';
import { TemplateCreatorEditor } from 'legacy/TemplateCreatorEditor';

import { handleErrorFocus } from './helpers';
import { useGetZodSchema } from './hooks';
import { FormValuesType } from './types';
import { LetterheadInfo } from './LetterheadInfo';
import { HistorySummaryProps } from '../History/HistorySummary';
import { RefreshIcon } from '../../../legacy/TemplateCreatorEditor/icons';
import { getHasHateoasAccess } from '../../../helpers';

type Props = HistorySummaryProps & {
  isEditFormOpen: boolean;
  template: TemplateType;
  onPreviewOpen: () => void;
  onBackToCurrent?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRevertVersion?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const TemplateEditForm = ({
  isEditFormOpen,
  template: { documentBranding, contractTemplate, ...template },
  onPreviewOpen,
  onOpenHistory,
  versionDate,
  versionNumber,
  isCurrentVersion,
  onBackToCurrent = () => null,
  onRevertVersion = () => null,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const { mutateAsync: editTemplate, isLoading: isEditingTemplate } =
    usePatchTemplate({ pathParams: { templateId: template.id } });

  const schema = useGetZodSchema();

  const formRef = useRef<HTMLFormElement>(null);

  const editorRef = useRef<{
    editorValueJson: object;
    setGeneralError: Dispatch<SetStateAction<string>>;
    triggerValidationAndGetErrors: () => string[];
  }>(null);

  const canRevert = getHasHateoasAccess('revert', template?.__links);

  const getDefaultTemplateType = (): FormValuesType['templateType'] => {
    if (template.base) {
      return 'base';
    }

    if (template.isOffer) {
      return 'offer';
    }

    return 'attachment';
  };

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    clearErrors,
    setError,
    setValue,
    watch,
  } = useForm<FormValuesType>({
    resolver: zodResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: template.name,
      signType: template.signType,
      certificateLanguages: template.certificatesLanguages,
      enableNumeric: template.enableNumeric,
      templateNumeric: template.templateNumeric || '',
      templateType: getDefaultTemplateType(),
      oneWay: template.oneWay,
      canEditByNegotiator: template.canEditByNegotiator,
    },
  });

  const onSubmit = async ({
    name,
    signType,
    certificateLanguages,
    enableNumeric,
    templateNumeric,
    templateType,
    oneWay,
    canEditByNegotiator,
  }: FormValuesType) => {
    const hasContractTemplateErrors =
      (editorRef.current?.triggerValidationAndGetErrors() || []).length > 0;

    if (!hasContractTemplateErrors) {
      try {
        await editTemplate({
          name,
          signType,
          certificatesLanguages: certificateLanguages,
          enableNumeric,
          templateNumeric,
          oneWay,
          canEditByNegotiator,
          base: templateType === 'base',
          isOffer: templateType === 'offer',
          contractTemplate: editorRef.current?.editorValueJson,
        });

        showNotification({
          message: t('templates:templateUpdated'),
        });
      } catch (error) {
        const {
          contractTemplate: contractTemplateError = '',
          ...validationServerErrors
        } = getServerErrorStatus(error);

        Object.entries(validationServerErrors).forEach(([field, message]) => {
          setError(field as keyof FormValuesType, {
            type: 'server',
            message,
          });
        });

        handleErrorFocus(validationServerErrors, formRef);

        editorRef.current?.setGeneralError(contractTemplateError);

        addServerErrorActions(error);
      }
    }
  };

  const onError = () => {
    editorRef.current?.triggerValidationAndGetErrors();
  };

  const templateType = watch('templateType');

  useEffect(() => {
    if (templateType === 'offer') {
      setValue('signType', SIGN_TYPES.any);
    }
  }, [setValue, templateType]);

  useEffect(() => {
    handleErrorFocus(formErrors, formRef);
  }, [formErrors]);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
        {isEditFormOpen && isCurrentVersion && (
          <FormAccordion
            sx={theme => ({
              marginBottom: theme.other.spacing(3),
            })}
            isError={Object.keys(formErrors).length > 0}
            versionDate={versionDate}
            versionNumber={versionNumber}
            onOpenHistory={onOpenHistory}
            isCurrentVersion={isCurrentVersion}
          >
            <Name
              error={formErrors.name?.message}
              nameInputProps={{ ...register('name') }}
            />
            <TemplateTypeField
              error={formErrors.templateType?.message}
              templateTypeRadioProps={{ ...register('templateType') }}
            />
            <SignType
              error={formErrors.signType?.message}
              signType={watch('signType')}
              signTypeRadioProps={{ ...register('signType') }}
              allowedItems={templateType === 'offer' ? [SIGN_TYPES.any] : 'all'}
            />
            <CertificateLanguages
              error={formErrors.certificateLanguages?.message}
              certificateLanguages={watch('certificateLanguages')}
              onCertificateLanguagesChange={certificateLanguages => {
                clearErrors('certificateLanguages');
                setValue('certificateLanguages', certificateLanguages);
              }}
            />
            <AutoNumeration
              error={formErrors.templateNumeric?.message}
              isTemplateNumericEnabled={watch('enableNumeric')}
              onTemplateNumericEnabledChange={enabled => {
                setValue('enableNumeric', enabled);
              }}
              templateNumeric={watch('templateNumeric')}
              onTemplateNumericChange={templateNumeric => {
                clearErrors('templateNumeric');
                setValue('templateNumeric', templateNumeric);
              }}
            />
            <Properties
              error={formErrors.oneWay?.message}
              oneWayCheckboxProps={{ ...register('oneWay') }}
            />
            <Permissions
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
              })}
              error={formErrors.canEditByNegotiator?.message}
              canEditByNegotiatorCheckboxProps={{
                ...register('canEditByNegotiator'),
              }}
            />
            <LetterheadInfo source={documentBranding.source} />
          </FormAccordion>
        )}
        <TemplateCreatorEditor
          // @ts-ignore
          sx={theme => ({ marginTop: theme.other.spacing(3) })}
          editorRef={editorRef}
          defaultValue={contractTemplate}
          readOnly={!isCurrentVersion}
        />
      </form>
      <ActionsBar
        items={
          isCurrentVersion
            ? [
                {
                  name: t('common:cancel'),
                  to: URLS.templates,
                  disabled: isEditingTemplate,
                },
                {
                  name: t('templates:templatePreview'),
                  action: () => onPreviewOpen(),
                  icon: <EyeIcon />,
                },
                {
                  name: t('common:save'),
                  action: () => {
                    formRef.current?.requestSubmit();
                  },
                  icon: <SaveIcon />,
                  color: 'blue',
                  isLoading: isEditingTemplate,
                },
              ]
            : ([
                {
                  name: t('templates:history.backToCurrent'),
                  action: onBackToCurrent,
                  color: 'blue',
                },
                canRevert && {
                  name: t('templates:history.revertVersion'),
                  action: onRevertVersion,
                  icon: <RefreshIcon />,
                },
                {
                  name: t('templates:templatePreview'),
                  action: onPreviewOpen,
                  icon: <EyeIcon />,
                },
              ].filter(Boolean) as ActionItemType[])
        }
      />
    </>
  );
};

import { Menu, ActionIcon } from '@mantine/core';
import { ContextMenuVerticalIcon, HistoryIcon } from 'icons';
import { MenuProps } from '@mantine/core/lib/Menu/Menu';
import { useTranslation } from 'react-i18next';

export type HistoryItemActionsProps = MenuProps & {
  onRevert: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const HistoryItemActions: React.FC<HistoryItemActionsProps> = ({
  onRevert,
  ...menuProps
}) => {
  const { t } = useTranslation('templates');

  return (
    <Menu shadow="md" {...menuProps}>
      <Menu.Target>
        <ActionIcon
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <ContextMenuVerticalIcon />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown onClick={event => event.stopPropagation()}>
        {onRevert && (
          <Menu.Item
            icon={<HistoryIcon />}
            onClick={event => {
              event.stopPropagation();
              onRevert(event);
            }}
          >
            {t('history.revert')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

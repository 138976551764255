export const resize = {
  renderMark: ({ attributes, mark, children, data }, editor, next) => {
    if (mark.type === 'resize') {
      return (
        <span {...attributes} style={{ fontSize: mark.data.get('fontSize') }}>
          {children}
        </span>
      );
    }

    return next();
  },
  queries: {
    resizedFontSizeData: editor => {
      return editor.value.activeMarks
        .find(mark => mark.type === 'resize')
        ?.data.get('fontSize');
    },
  },
  commands: {
    toggleResize: (editor, fontSize) => {
      editor.value.marks
        .filter(mark => mark.type === 'resize')
        .toJS()
        .forEach(mark => {
          editor.removeMark({
            type: 'resize',
            data: { fontSize: mark.data.fontSize },
          });
        });

      editor.addMark({ type: 'resize', data: { fontSize } });

      editor.value.inlines
        .filter(inline => inline.type === 'variable' || inline.type === 'input')
        .forEach(inline => {
          editor.updateNodeData(inline, { fontSize });
        });
    },
  },
};

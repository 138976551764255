import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getTemplateVersion } from '../requests';
import { GetTemplateRequestType } from '../types';
import { templateMigrator } from '../helpers';

export const useGetTemplateVersion = (
  {
    pathParams: { templateId, version },
  }: {
    pathParams: GetTemplateRequestType['pathParams'];
  },
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    enabled: Boolean(templateId && version),
    queryKey: [
      API_QUERY_KEYS.templateVersion,
      {
        templateId: Number(templateId),
        version,
      },
    ],
    queryFn: () =>
      getTemplateVersion({
        pathParams: { templateId, version },
      }).then(templateMigrator),
    ...options,
  });

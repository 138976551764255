import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';

import { useGetCurrentBoard } from 'api/boards';
import { URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { Summary } from './Summary';
import { TemplateEditForm } from './TemplateEditForm';
import { TemplatePreviewModal } from './TemplatePreviewModal/TemplatePreviewModal';
import { HistoryDrawer } from './HistoryDrawer/HistoryDrawer';
import { useTemplate } from '../../hooks/useTemplate';
import { usePostTemplateVersionsRevert } from '../../api/templates/hooks';
import { LoadingModal } from '../../components/shared/Modals';

export const TemplateEdit = () => {
  const { t } = useTranslation('templates');
  const [loadingVersion, setLoadingVersion] = useState<number | undefined>();
  const [openedHistory, { open: setOpenHistory, close: closeHistory }] =
    useDisclosure(false);

  const { templateId } = useParams() as {
    templateId: string;
  };

  const { mutateAsync: revertVersion } =
    usePostTemplateVersionsRevert(templateId);

  const [version, setVersion] = useState<number | undefined>();
  const [versionOrderNumber, setVersionOrderNumber] = useState<
    number | undefined
  >();
  const [isClosingSummary, setIsClosingSummary] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const {
    data: templateData,
    isError: isTemplateError,
    isTemplateLoading,
    isTemplateVersionLoading,
    refetch,
  } = useTemplate(templateId, { enabled: hasBoardTemplateFeature, version });

  // This mechanism is used to display loading modal when template version is loading
  // Render need more time to display new template state, that we need to display loading modal with delay
  useEffect(() => {
    let timeout: any;

    if (loadingVersion !== version) {
      if (!isTemplateVersionLoading) {
        timeout = setTimeout(() => {
          setLoadingVersion(version);
        }, 1000);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isTemplateVersionLoading, loadingVersion, version]);

  const displayVersion = versionOrderNumber ?? templateData?.versionsCount;
  const isCurrentVersion = displayVersion === templateData?.versionsCount;

  const hasTemplateUpdateAccess = getHasHateoasAccess(
    'update',
    templateData?.__links,
  );

  const canLookHistory = getHasHateoasAccess('versions', templateData?.__links);
  const openHistory = canLookHistory ? setOpenHistory : undefined;

  const backToCurrent = () => {
    setVersion(undefined);
    setVersionOrderNumber(undefined);
  };

  const handleRevertVersion = async (
    event: React.MouseEvent<HTMLButtonElement>,
    revertToVersion: number,
  ) => {
    await revertVersion(revertToVersion);
    setLoadingVersion(-1);
    setVersion(undefined);
    setVersionOrderNumber(undefined);
    refetch();
  };

  const handleSelectedVersionChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    selectedVersionId?: number,
    selectedVersionOrderNumber?: number,
  ) => {
    if (templateData?.versionId !== selectedVersionId) {
      setVersion(selectedVersionId);
      setVersionOrderNumber(selectedVersionOrderNumber);
    }
  };

  return (
    <>
      <LoadingModal opened={loadingVersion !== version} />
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={templateData}
        isError={isCurrentBoardError || isTemplateError}
        isLoading={isCurrentBoardLoading || isTemplateLoading}
        isNoAccess={!hasBoardTemplateFeature && !isCurrentBoardLoading}
        errorMargin="xl"
      >
        {template => (
          <>
            <HistoryDrawer
              countVersions={template?.versionsCount ?? 0}
              opened={openedHistory}
              onClose={closeHistory}
              templateId={templateId}
              selectedVersion={version}
              onRevertVersion={handleRevertVersion}
              onSelectedVersionChange={handleSelectedVersionChange}
            />
            <MetaTitle
              value={`${template.name} - ${t('templates:templates')}`}
            />
            <Breadcrumbs
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
              })}
              items={[
                { name: t('templates:templates'), url: URLS.templates },
                { name: template.name, url: '#' },
              ]}
            />
            {(!isEditFormOpen || !isCurrentVersion) && (
              <Summary
                template={template}
                isLoading={isClosingSummary}
                onOpenHistory={openHistory}
                versionDate={template.updatedAt}
                versionNumber={versionOrderNumber || template.versionsCount}
                isCurrentVersion={isCurrentVersion}
                onEdit={
                  hasTemplateUpdateAccess
                    ? () => {
                        setIsClosingSummary(true);

                        setTimeout(() => {
                          setIsClosingSummary(false);
                          setIsEditFormOpen(true);
                        }, 1000);
                      }
                    : undefined
                }
              />
            )}
            <TemplateEditForm
              isEditFormOpen={isEditFormOpen}
              template={template}
              onPreviewOpen={() => setIsPreviewModalOpen(true)}
              onOpenHistory={openHistory}
              versionDate={template.updatedAt}
              versionNumber={versionOrderNumber || template.versionsCount}
              isCurrentVersion={isCurrentVersion}
              onRevertVersion={event => handleRevertVersion(event, version!)}
              onBackToCurrent={() => backToCurrent()}
            />
            <TemplatePreviewModal
              isOpen={isPreviewModalOpen}
              onClose={() => setIsPreviewModalOpen(false)}
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};

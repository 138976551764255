import { useEffect, useState } from 'react';
import { Box } from '@mantine/core';
import YAML from 'yaml';

import { Providers } from './Providers';
import { TopSection } from './TopSection';
import { BottomSection } from './BottomSection';
import { EditorArea } from './EditorArea';
import { Errors } from './Errors';

import { getTemplateVariablesApiSchema } from './helpers';

export const Root = ({ editor, errors, textEditor }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const variables = editor.getVariables();

  useEffect(() => {
    // for CS department purposes
    const schema = getTemplateVariablesApiSchema(variables);
    window.getTemplateVariablesApiJsonSchema = () => {
      console.log(JSON.stringify(schema, null, 2));
    };
    window.getTemplateVariablesApiYamlSchema = () => {
      console.log(YAML.stringify(schema));
    };
  }, [variables]);

  return (
    <Providers editor={editor} errors={errors}>
      <Box
        sx={theme => ({
          fontSize: theme.fontSizes.lg,
        })}
      >
        {!editor.props.readOnly && (
          <Box
            sx={theme => ({
              position: 'sticky',
              top: theme.other.spacing(-2),
              zIndex: 2,
              backgroundColor: theme.colors.blue[1],
              border: `1px solid ${theme.colors.blue[2]}`,
            })}
          >
            <TopSection />
            <BottomSection
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            <Errors />
          </Box>
        )}
        <EditorArea textEditor={textEditor} isSidebarOpen={isSidebarOpen} />
      </Box>
    </Providers>
  );
};

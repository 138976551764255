import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LoadingOverlay, Text, UnstyledButton } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';

import { Error, NotFound, TextInputSearch } from 'components/shared';

import { ItemType, SharedPropsType } from '../types';

type Props = SharedPropsType & {
  readOnly?: boolean;
};

export const List = ({
  isError,
  isLoading,
  selectedId,
  itemsData,
  onChange,
  fetchNextPage,
  searchInputProps,
  notFoundProps,
  uniqueKey = 'id',
  readOnly,
}: Props) => {
  const { t } = useTranslation('common');

  const listRef = useRef<HTMLUListElement>(null);

  const { ref: lastItemRef, entry: lastItemEntry } = useIntersection({
    root: listRef.current,
    threshold: 1,
  });

  useEffect(() => {
    if (lastItemEntry?.isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, lastItemEntry?.isIntersecting]);

  const items = itemsData?.pages.map(({ data }) => data)?.flat() || [];

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <TextInputSearch
        readOnly={readOnly}
        sx={theme => ({
          margin: theme.other.spacing(0, 2, 2),
        })}
        autoFocus
        {...searchInputProps}
      />
      {isError && <Error />}
      {(items.length > 0 || !isLoading) && !isError && (
        <Box
          sx={{ maxHeight: 355, overflow: 'auto' }}
          component="ul"
          ref={listRef}
        >
          {items.length === 0 ? (
            <NotFound
              sx={theme => ({
                margin: theme.other.spacing(8, 0),
              })}
              {...notFoundProps}
            />
          ) : (
            items.map((item, index) => {
              const id = item[uniqueKey as keyof ItemType];

              return (
                <li key={id}>
                  <UnstyledButton
                    sx={theme => ({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      minHeight: 40,
                      padding: theme.other.spacing(1, 2),
                      fontSize: theme.fontSizes.lg,
                      wordBreak: 'break-word',
                      backgroundColor:
                        id === selectedId
                          ? theme.colors[theme.primaryColor][1]
                          : undefined,
                      '&:hover': {
                        backgroundColor: theme.colors[theme.primaryColor][1],
                      },
                    })}
                    ref={index === items.length - 1 ? lastItemRef : undefined}
                    onClick={() => {
                      onChange(item);
                    }}
                  >
                    <Text
                      sx={theme => ({
                        marginRight: theme.other.spacing(0.5),
                        fontWeight: id === selectedId ? 600 : undefined,
                      })}
                      span
                    >
                      {item.name}
                    </Text>
                    {uniqueKey === 'id' && (
                      <Text
                        size="xs"
                        color="gray.7"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          flexShrink: 0,
                        }}
                      >
                        {t('common:id')} {id}
                      </Text>
                    )}
                  </UnstyledButton>
                </li>
              );
            })
          )}
        </Box>
      )}
    </div>
  );
};

import { apiAuthClient } from 'api/helpers';
import {
  GetTemplateVersionsRequestType,
  GetTemplateVersionsResponseType,
} from '../types';

export const getTemplateVersions = async ({
  pathParams: { templateId },
}: GetTemplateVersionsRequestType) => {
  const url = `/api/v2/templates/${templateId}/versions?sort_by=version_id&sort_dir=desc&per_page=100`;

  const { data } = await apiAuthClient.get<GetTemplateVersionsResponseType>(
    url,
  );

  return data.data;
};

import { Box, BoxProps, Text, UnstyledButton } from '@mantine/core';
import { GetTemplateVersionsData } from 'api/templates/types/getTemplateVersionsTypes';
import { useFormatUserDate } from 'hooks';
import { User } from 'components/shared/User';
import { useTranslation } from 'react-i18next';
import { HistoryItemActions } from './HistoryItemActions';
import { getHasHateoasAccess } from '../../../helpers';

export type HistoryItemProps = BoxProps & {
  /**
   * Template version data
   */
  data: GetTemplateVersionsData;

  /**
   * Version index
   */
  index: number;

  /**
   * If version is current
   */
  isCurrent?: boolean;

  /**
   * If version is selected
   */
  isSelected?: boolean;

  /**
   * Function to handle selected version change
   * @param event - click event
   * @param version - new selected version
   * @param orderNumber - order number of the version
   */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    version?: number,
    orderNumber?: number,
  ) => void;

  /**
   * Function to handle revert to specific version
   * @param event - click event
   * @param version - version to revert to
   */
  onRevertVersion?: (
    event: React.MouseEvent<HTMLButtonElement>,
    version: number,
  ) => void;
};

/**
 * Component to display single template version in history
 */
export const HistoryItem: React.FC<HistoryItemProps> = ({
  isCurrent,
  isSelected,
  index,
  data,
  onRevertVersion,
  onClick,
  ...boxProps
}) => {
  const { t } = useTranslation('templates');
  const dateFormater = useFormatUserDate();

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    /**
     * If the version is current, we don't want to pass it to the parent component
     * undefined is a signal that the current version should be displayed
     */
    onClick?.(
      event,
      isCurrent ? undefined : data.version,
      isCurrent ? undefined : index,
    );
  };

  const canRevert = !isCurrent && getHasHateoasAccess('revert', data?.__links);

  return (
    <UnstyledButton
      sx={theme => ({
        width: '100%',
        background: isSelected ? theme.colors.violet[1] : 'transparent',
        padding: '8px',
        borderRadius: '4px',
        '&:hover': {
          background: theme.colors.violet[1],
        },
      })}
      onClick={handleOnClick}
      {...boxProps}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '4px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flex: '0 1 100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Text
            size="md"
            weight={600}
            sx={theme => ({
              color: isCurrent ? theme.colors.violet[0] : undefined,
            })}
          >
            {t('history.version', { version: index })}{' '}
            {isCurrent && t('history.current')}
          </Text>
          <Text size="sm" weight={400}>
            {dateFormater(data.createdAt)}
          </Text>

          <User markMe data={data.responsible} maxWidthLabel={180} />
        </Box>
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {canRevert && (
            <HistoryItemActions
              onRevert={event => onRevertVersion?.(event, data.version)}
            />
          )}
        </Box>
      </Box>
    </UnstyledButton>
  );
};

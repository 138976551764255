import { useEffect, useState } from 'react';
import { useGetTemplate } from '../api/templates';
import { mergeTemplateAndVersion } from '../helpers/mergeTemplateAndVersion';
import { useTemplateVersion } from './useTemplateVersion';
import { TemplateType } from '../types';

/**
 * Fetches template data and version data if version is provided
 * This hook is a combination of useGetTemplate and useGetTemplateVersion
 */
export const useTemplate = (
  templateId: string,
  options?: {
    version?: number;
    enabled?: boolean;
  },
) => {
  const [data, setData] = useState<TemplateType | undefined>();
  const version = options?.version;
  const templateData = useGetTemplate({ pathParams: { templateId } }, options);
  const templateVersionData = useTemplateVersion(templateId, version);

  const isTemplateLoading = templateData.isLoading;
  const isTemplateVersionLoading = version
    ? templateVersionData.isLoading
    : false;
  const isLoading = isTemplateLoading || isTemplateVersionLoading;
  const isError = templateData.isError || templateVersionData.isError;

  useEffect(() => {
    if (!isLoading) {
      setData(
        mergeTemplateAndVersion(templateData.data, templateVersionData.data),
      );
    }
  }, [isLoading, templateData.data, templateVersionData.data]);

  return {
    ...templateData,
    isLoading,
    isTemplateLoading,
    isTemplateVersionLoading,
    isError,
    data:
      data ??
      mergeTemplateAndVersion(templateData.data, templateVersionData.data),
  };
};

import { Value } from 'slate';
import HtmlSerializer from 'slate-html-serializer';
import * as yup from 'yup';
import writtenNumber from 'written-number';
import i18next from 'i18next';

import { createRules } from './createRules';
import { evaluateConditions } from './evaluateConditions';
import { plLocale } from './locale/pl';
import { deLocale } from './locale/de';

/**
 * This method check given object and remove empty conditions. When all conditions are empty, then we return null
 * @param {object|null} requiredWhen
 */
const removeEmptyConditions = requiredWhen => {
  if (!requiredWhen) {
    return requiredWhen;
  }

  const oldConditions = requiredWhen.conditions || [];

  const conditions = oldConditions.filter(c => c.variableName);

  if (conditions.length === 0) {
    return null;
  }

  return {
    conditions,
    logicalOperator: requiredWhen.logicalOperator,
  };
};

export const renderTemplatePreview = template => {
  const slateObject = template && Value.fromJSON(template);
  const rules = createRules(template, slateObject);
  const serializer = new HtmlSerializer({ rules });

  return serializer.serialize(slateObject, { render: false });
};

export const getTemplateVariablesValues = template =>
  getSchema(template).cast();

const schemaMap = {
  text: yup.string().required(i18next.t('common:formErrors.required')),
  textarea: yup.string().required(i18next.t('common:formErrors.required')),
  integer: yup.number().integer().strict(true).optional().nullable(),
  boolean: yup
    .boolean()
    .default(true)
    .required(i18next.t('common:formErrors.required')),
  select: yup.string().nullable(),
  tabs: yup.string().required().optional(),
  money: yup.number().min(0).max(9999999).optional().nullable(),
  date: yup
    .mixed()
    .required(i18next.t('common:formErrors.required'))
    .nullable()
    .default(null)
    .transform(value => {
      if (typeof value === 'string') {
        return new Date(value);
      }

      return value;
    }),
  company: yup.mixed().required().nullable(),
  dynamicList: yup.string().required(),
};

export const getSchema = (template, allOptional = false) =>
  yup.object(
    (template.document.data.variables || []).reduce(
      (prev, variable, i, allVariables) => {
        if (variable.type === 'conditional') {
          return prev;
        }

        let value = schemaMap[variable.type];

        if (
          (variable.type === 'integer' || variable.type === 'money') &&
          !['', null, undefined].includes(variable.minValue)
        ) {
          value = value.min(
            variable.minValue,
            i18next.t('common:formErrors.minValue', { min: variable.minValue }),
          );
        }

        if (
          (variable.type === 'integer' || variable.type === 'money') &&
          !['', null, undefined].includes(variable.maxValue)
        ) {
          value = value.max(
            variable.maxValue,
            i18next.t('common:formErrors.maxValue', { max: variable.maxValue }),
          );
        }

        if (variable.type === 'select' || variable.type === 'tabs') {
          value = value.oneOf([
            ...variable.options.map(v => v.value),
            null,
            '',
          ]);
        }

        const initialValue = getInitialVariableValue(variable);

        let variableSchema;

        if (variable.list && variable.requiredWhen) {
          const varsKeys = allVariables
            .filter(v => !v.requiredWhen)
            .map(v => v.name);

          variableSchema = yup
            .array()
            .when(varsKeys, (varsValues, schema) => {
              return schema.of(
                maybeAddOptionalityToSchema(
                  value,
                  variable,
                  allVariables,
                  allOptional,
                  varsValues,
                ),
              );
            })
            .default([initialValue, initialValue]);
        } else if (variable.list) {
          variableSchema = yup
            .array(
              maybeAddOptionalityToSchema(
                value,
                variable,
                allVariables,
                allOptional,
              ),
            )
            .default([initialValue, initialValue]);
        } else if (variable.type === 'dynamicList') {
          variableSchema = maybeAddOptionalityToSchema(
            value,
            variable,
            allVariables,
            allOptional,
          ).default(initialValue);
        } else {
          if (variable.regExp) {
            const regex = new RegExp(variable.regExp);

            value = value.test(
              'regExp',
              `${i18next.t('common:formErrors.regExp')} ${regex}`,
              v => (v ? regex.test(v) : true),
            );
          }

          variableSchema = maybeAddOptionalityToSchema(
            value,
            variable,
            allVariables,
            allOptional,
          ).default(initialValue);
        }

        return {
          ...prev,
          [variable.name]: variableSchema,
        };
      },
      {},
    ),
  );

const getInitialVariableValue = variable => {
  if (variable.type === 'integer' && variable.initialValue !== null) {
    return Number(variable.initialValue);
  }

  if (variable.initialValue !== undefined) {
    return variable.initialValue;
  }

  if (variable.type === 'company') {
    return null;
  }

  return undefined;
};

const maybeAddOptionalityToSchema = (
  schema,
  variable,
  allVariables,
  allOptional,
  parentSiblingValues = undefined,
) => {
  if (!variable.required || (allOptional && !variable.requiredWhen)) {
    return schema.notRequired().nullable();
  }

  if (removeEmptyConditions(variable.requiredWhen)) {
    const varsKeys = allVariables.filter(v => !v.requiredWhen).map(v => v.name);

    if (varsKeys.length > 0) {
      return schema.when(varsKeys, (_varsValues, schemaNested) => {
        const varsValues = parentSiblingValues || _varsValues;
        const contractValues = varsKeys.reduce((previous, key, index) => {
          previous[key] = varsValues[index];
          return previous;
        }, {});

        const shouldBeRequired = evaluateConditions(
          variable.requiredWhen,
          allVariables,
          contractValues,
        );

        if (shouldBeRequired && !allOptional) {
          return schemaNested;
        }

        if (shouldBeRequired && allOptional) {
          return schemaNested.notRequired().nullable();
        }

        return yup.mixed().notRequired().nullable();
      });
    }
  }

  return schema;
};

export const moneyToWords = (number, currency, langCode = 'pl') => {
  if (!parseFloat(number)) return '';

  let lang = 'en';
  if (langCode === 'pl') lang = plLocale;
  else if (langCode === 'de') lang = deLocale;

  const numberString = number.toString();
  if (!numberString.includes('.')) {
    return `(${writtenNumber(number, { lang })}) ${currency}`;
  }

  const [dollars, cents] = numberString.split('.');
  return `(${writtenNumber(Number(dollars), { lang })} ${cents}${
    cents.length === 1 ? '0' : ''
  }/100) ${currency}`;
};

export const getVariableName = (variable, loopWrapperIndex) => {
  if (!variable) {
    return undefined;
  }

  if (variable.list && loopWrapperIndex !== null) {
    return variable.name + `.${loopWrapperIndex}`;
  } else if (variable.list) {
    // Fallback for situation when we not have loopWrapperIndex
    // probably because the user is using a variable with a loop variable outside the loop
    return variable.name + `.0`;
  }

  return variable.name;
};

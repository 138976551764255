import { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';
import { Editor } from 'slate-react';
import { Value, Block } from 'slate';

import { DEFAULT_CONTRACT_TEMPLATE } from 'consts';
import { getAreObjectsEqualByJsonRepresentation } from 'helpers';
import { FieldErrorMessage } from 'components/shared';
import { CONFIG } from 'legacy/TemplateCreatorEditor/consts';
import {
  bold,
  companyWrapper,
  conditionalWrapper,
  header,
  input,
  italic,
  list,
  loopWrapper,
  pageBreak,
  paragraph,
  resize,
  root,
  signatures,
  staticTable,
  strikethrough,
  table,
  title,
  tooltip,
  underline,
  variable,
} from './plugins';
import { useGetErrors } from 'legacy/TemplateCreatorEditor/hooks';
import { useTemplateStore } from 'stores';

const plugins = [
  bold,
  companyWrapper,
  conditionalWrapper,
  header,
  input,
  italic,
  list,
  loopWrapper,
  pageBreak,
  paragraph,
  resize,
  root,
  signatures,
  staticTable,
  strikethrough,
  table,
  title,
  tooltip,
  underline,
  variable,
];

/**
 * Ensure that the last node in the editor is a paragraph
 */
const ensureLastNodeIsParagraph = value => {
  const lastNode = value.document.nodes.last();

  if (lastNode.type !== 'paragraph') {
    const newParagraph = Block.create('paragraph');
    const newNodes = value.document.nodes.push(newParagraph);
    const newDocument = value.document.set('nodes', newNodes);
    return value.set('document', newDocument);
  }
  return value;
};

export const TemplateCreatorEditor = ({
  editorRef,
  defaultValue,
  readOnly = false,
  sx,
}) => {
  const { t } = useTranslation('templates');
  const getErrors = useGetErrors();
  const [value, setValue] = useState(
    ensureLastNodeIsParagraph(
      Value.fromJSON(defaultValue || DEFAULT_CONTRACT_TEMPLATE),
    ),
  );
  const { setEditorJsonValue } = useTemplateStore();
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState([]);
  const editorValueJson = value.toJSON();
  const isEditorEmpty = getAreObjectsEqualByJsonRepresentation(
    editorValueJson.document.nodes,
    DEFAULT_CONTRACT_TEMPLATE.document.nodes,
  );
  const triggerValidationAndGetErrors = () => {
    const newErrors = getErrors(editorValueJson);

    setErrors(newErrors);

    return newErrors;
  };

  useImperativeHandle(editorRef, () => ({
    editorValueJson,
    setGeneralError,
    triggerValidationAndGetErrors,
  }));

  useEffect(() => {
    if (defaultValue) {
      let initialValue = Value.fromJSON(defaultValue);
      initialValue = ensureLastNodeIsParagraph(initialValue);
      setValue(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setEditorJsonValue(value.toJSON());
  }, [value]);

  const handleOnChange = ({ value }) => {
    setGeneralError('');
    const newValue = ensureLastNodeIsParagraph(value);
    setValue(newValue);
  };

  return (
    <Box sx={sx}>
      <Editor
        readOnly={readOnly}
        style={{
          minHeight: 600,
          fontSize: CONFIG.fontSize,
          lineHeight: CONFIG.lineHeight,
          textAlign: CONFIG.textAlign,
        }}
        value={value}
        onChange={handleOnChange}
        plugins={plugins}
        placeholder={isEditorEmpty ? t('templates:startHere') : undefined}
        // custom props
        errors={errors}
      />
      <FieldErrorMessage>{generalError}</FieldErrorMessage>
    </Box>
  );
};

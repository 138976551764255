import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getTemplateVersions } from '../requests';
import { GetTemplateVersionsRequestType } from '../types';

export const useGetTemplateVersions = (
  {
    pathParams: { templateId },
  }: {
    pathParams: GetTemplateVersionsRequestType['pathParams'];
  },
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.templateVersions,
      {
        templateId: Number(templateId),
      },
    ],
    queryFn: () =>
      getTemplateVersions({
        pathParams: { templateId },
      }).then(data => data),
    ...options,
  });

import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ContextMenuVerticalIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="5" r="1.5" fill={iconColor} />
      <circle cx="12" cy="12" r="1.5" fill={iconColor} />
      <circle cx="12" cy="19" r="1.5" fill={iconColor} />
    </Box>
  );
};

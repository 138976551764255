import { Box, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import * as React from 'react';

type InfoItemProps = {
  label: string;
  value?: string | null;
  children?: React.ReactNode;
};

export const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  children,
}) => {
  const largeScreen = useMediaQuery('(min-width: 768px)');

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Text
        sx={theme => ({
          width: largeScreen ? 200 : '50%',
          marginRight: theme.other.spacing(2),
          textAlign: largeScreen ? 'right' : 'left',
        })}
        size="md"
      >
        {label}:
      </Text>
      {(Boolean(value) && (
        <Text
          sx={{
            flexShrink: 0,
            maxWidth: 275,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          size="md"
          weight={600}
        >
          {value}
        </Text>
      )) ||
        children}
    </Box>
  );
};

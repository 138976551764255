import { useTranslation } from 'react-i18next';

import { Tooltip, ActionIcon, useMantineTheme } from '@mantine/core';
import {
  InsertColumnIcon,
  InsertRowIcon,
  RemoveColumnIcon,
  RemoveRowIcon,
} from 'legacy/TemplateCreatorEditor/icons';
import { VariableContextMenu } from '../../../VariableContextMenu';

export const TableToolbar = ({
  editor,
  isFocused,
  isSelected,
  node,
  plugin,
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation(['templates']);

  const numberOfColumns = node.toJS().nodes[0].nodes.length;

  const handleOnRemoveClick = () => {
    editor.command(plugin.changes.removeTableByKey, node.key);
  };

  return (
    <div
      contentEditable={false}
      style={{
        minHeight: '28px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.other.spacing(1),
        marginBottom: theme.other.spacing(1),
      }}
    >
      {isSelected && isFocused && (
        <>
          <Tooltip label={t('table.insertColumn')} position="top">
            <ActionIcon
              disabled={numberOfColumns >= 8}
              onClick={() => editor.command(plugin.changes.insertColumn)}
            >
              <InsertColumnIcon />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('table.removeColumn')} position="top">
            <ActionIcon
              onClick={() => editor.command(plugin.changes.removeColumn)}
            >
              <RemoveColumnIcon />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('table.insertRow')} position="top">
            <ActionIcon
              disabled={numberOfColumns >= 8}
              onClick={() => editor.command(plugin.changes.insertRow)}
            >
              <InsertRowIcon />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('table.removeRow')} position="top">
            <ActionIcon
              onClick={() => editor.command(plugin.changes.removeRow)}
            >
              <RemoveRowIcon />
            </ActionIcon>
          </Tooltip>
        </>
      )}
      <VariableContextMenu
        disabled={editor.props.readOnly}
        removeLabel="table.removeTable"
        onRemoveClick={handleOnRemoveClick}
      />
    </div>
  );
};

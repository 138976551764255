import { Box, ModalProps, Modal, Loader } from '@mantine/core';

export type LoadingModalProps = Omit<ModalProps, 'onClose'>;
export const LoadingModal: React.FC<LoadingModalProps> = modalProps => (
  <Modal
    centered
    size={196}
    withCloseButton={false}
    onClose={() => null} // this modal is controlled only by opened prop
    {...modalProps}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 120 - 32, // 32 is adding by padding
      }}
    >
      <Loader />
    </Box>
  </Modal>
);
